(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("thunderboltElements", ["React", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["thunderboltElements"] = factory(require("React"), require("ReactDOM"));
	else
		root["thunderboltElements"] = factory(root["React"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__137__, __WEBPACK_EXTERNAL_MODULE__314__) {
return 